/* eslint-disable */
import React, { useEffect, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getActiveSubs, getNewSubs } from "../../../actions";
import "./styles.scss";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";

const PremiumUsers = ({
  getStatisticsSuccess,
  getIncomeRevenue,
  newSubscriptions,
  getRegisteredUsers,
  activeSubscriptions,
  getInnerLicenses,
  getInnerVehicles,
  registeredUsers,
  getNewSubs,
  getActiveSubs,
}) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[6].value,
    endDate: moment().format("YYYY-MM-DD") + "T00:00:00.000Z",
    label: options[6].label,
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);

  useEffect(() => {
    getStatisticsSuccess({ type: "newSubscriptions", loading: true });
    getStatisticsSuccess({ type: "activeSubscriptions", loading: true });
    getActiveSubs();
    getNewSubs({
      startDate: options[6].value,
      endDate: moment().startOf("day").format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      if (!selPeriod.startDate) {
        getNewSubs({
          ...selPeriod,
          label: undefined,
          onSuccess: () => setInnerLoading(false),
        });
      } else {
        getNewSubs({
          ...selPeriod,
          label: undefined,
          onSuccess: () => setInnerLoading(false),
        });
      }
    }
  }, [selPeriod]);

  const calculateUsers = () => {
    var sum = 0;
    activeSubscriptions?.elements?.map((sub) => {
      sum += sub?.subscriptions?.reduce((acc, current) => acc?.userCount + current?.userCount);
    });
    return sum;
  };

  return (
    <>
      <div className="statistics-sessions-container col">
        {innerLoading === true ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <div style={{ display: "flex", width: "100%" }}>
              <h2 className="main-header" style={{marginLeft:"0px"}}>
                <b>premium user profiles</b>
              </h2>
              <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
            </div>
            <div className="border-container background" style={{ marginRight: "1%" }}>
              <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                <div className="indicator-circle dark-gray" />
                <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                  <b style={{ fontSize: "19px" }}>{calculateUsers()}</b> REGISTERED USERS
                </h2>
              </div>
            </div>
            <div className="outer-container">
              <h2 className="main-header" style={{ fontSize: "17px", margin: "10px 0px" }}>
                Yearly:
              </h2>
              {activeSubscriptions?.elements?.map((obj, i) => {
                return (
                  <div className="border-container" style={{ width: "49%" }} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {obj?.subscriptions?.filter((el) => el?.type === "yearly")[0]?.userCount}
                        <p style={{ fontSize: "15px", textTransform: "uppercase" }}>{obj?.source}</p>
                      </h2>
                    </div>
                  </div>
                );
              })}
              <h2 className="main-header" style={{ fontSize: "17px", margin: "10px 0px" }}>
                Monthly:
              </h2>
              {activeSubscriptions?.elements?.map((obj, i) => {
                return (
                  <div className="border-container" style={{ width: "49%" }} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {obj?.subscriptions?.filter((el) => el?.type === "monthly")[0]?.userCount}
                        <p style={{ fontSize: "15px", textTransform: "uppercase" }}>{obj?.source}</p>
                      </h2>
                    </div>
                  </div>
                );
              })}
              <h2 className="main-header" style={{ marginLeft: "0px" }}>
                <b>new premium profiles</b>
              </h2>
              <h2 className="main-header" style={{ fontSize: "17px", margin: "10px 0px" }}>
                Yearly:
              </h2>
              {newSubscriptions?.elements?.map((obj, i) => {
                return (
                  <div className="border-container" style={{ width: "49%" }} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {obj?.subscriptions?.filter((el) => el?.type === "yearly")[0]?.userCount}
                        <p style={{ fontSize: "15px", textTransform: "uppercase" }}>{obj?.source}</p>
                      </h2>
                    </div>
                  </div>
                );
              })}
              <h2 className="main-header" style={{ fontSize: "17px", margin: "10px 0px" }}>
                Monthly:
              </h2>
              {newSubscriptions?.elements?.map((obj, i) => {
                return (
                  <div className="border-container" style={{ width: "49%" }} key={i}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div className="indicator-circle gray" />
                      <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "18px" }}>
                        {obj?.subscriptions?.filter((el) => el?.type === "monthly")[0]?.userCount}
                        <p style={{ fontSize: "15px", textTransform: "uppercase" }}>{obj?.source}</p>
                      </h2>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeSubscriptions: state.dashboard.activeSubscriptions,
  newSubscriptions: state.dashboard.newSubscriptions,
});
const mapDispatchToProps = (dispatch) => ({
  getActiveSubs: (payload) => dispatch(getActiveSubs(payload)),
  getNewSubs: (payload) => dispatch(getNewSubs(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PremiumUsers);
