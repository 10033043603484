import React, { useState } from "react";
import Popup from "reactjs-popup";
import { monthOptions, options } from "../../../config/constants";
import Inputs from "../../Inputs";
import moment from "moment";
import _ from "lodash";

const SelectPeriod = ({ selPeriod, setSelPeriod, setFiltered, growth, selected, startField, endField, noCustom }) => {
  const [customFields, setCustomFields] = useState(false);

  const handleTextSelector = () => {
    if (growth) {
      const foundMonth = monthOptions.find((opt) => opt[startField] === selPeriod[startField]);
      return foundMonth ? foundMonth.label : "All";
    } else {
      const foundPeriod = options.find((opt) => opt.value === selPeriod[startField]);
      return foundPeriod ? foundPeriod.label : ["Custom", "custom"].includes(selPeriod?.label) ? "Custom" : "All";
    }
  };

  const periodOnChange = (period, close) => {
    if (growth) {
      setSelPeriod({
        [startField]: period[startField] === null ? null : period[startField],
        [endField]: period?.[endField] === null ? null : period?.[endField],
      });
      setFiltered(true);
      close();
    } else {
      if (period === "custom") {
        setFiltered(false);
        setCustomFields(true);
        setSelPeriod({ ...selPeriod, label: "Custom" });
      } else {
        setSelPeriod({
          ...selPeriod,
          [startField]: period?.value === null ? null : period,
          label: period.label,
        });
        setFiltered(true);
        close();
      }
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <p className="period-text">{handleTextSelector()}</p>
      <Popup
        className="popup-header-options-container"
        onOpen={() => {
          if (["Custom", "custom"].includes(selPeriod?.label) && !growth) {
            setFiltered(false);
            setCustomFields(true);
          }
        }}
        trigger={<div className={`settings-icon ${selected && "selected"}`} />}
        position="bottom right"
        contentStyle={{ padding: "10px", width: 240 }}
      >
        {(close) => (
          <>
            <Inputs.RadioButtons options={growth ? monthOptions : noCustom ? _.concat(_.slice(options, 0, options.length - 2), _.last(options)) : options} column value={selPeriod?.label && selPeriod?.label === "Custom" ? "custom" : selPeriod[startField]} onChange={(period) => periodOnChange(period, close)} />
            {customFields && (
              <>
                <Inputs.DatePicker label={"Start date"} style={{ width: "220px" }} maxDate={moment()._d} value={selPeriod[startField] ? moment(selPeriod[startField])?._d : moment()._d} onChange={(e) => setSelPeriod({ ...selPeriod, [startField]: moment(e).toISOString() })} />
                <Inputs.DatePicker label={"End date"} style={{ width: "220px", marginTop: "10px" }} maxDate={moment()._d} value={selPeriod[endField] ? moment(selPeriod[endField])?._d : null} onChange={(e) => setSelPeriod({ ...selPeriod, [endField]: moment(e).toISOString() })} />

                <Inputs.Button
                  text={"SAVE"}
                  selected
                  style={{ width: "220px", marginTop: "10px" }}
                  onClick={() => {
                    setSelPeriod({ ...selPeriod, label: "Custom" });
                    setFiltered(true);
                    close();
                  }}
                />
              </>
            )}
          </>
        )}
      </Popup>
    </div>
  );
};

export default SelectPeriod;
