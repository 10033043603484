/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import Statistics from "..";
import { getStatisticsSuccess, getVehicles } from "../../../actions";
import "./styles.scss";
import { connect } from "react-redux";
import moment from "moment";
import { options } from "../../../config/constants";
import Inputs from "../../Inputs";

const horizontalObjects = [
  {
    head: "BRAND",
    valueKey: "byBrand",
  },
  {
    head: "REGISTRATION PLATE",
    valueKey: "byRegion",
  },
  {
    head: "VEHICLES SHARED BETWEEN USERS",
    valueKey: "byUsers",
  },
];

const Sessions = ({ getStatisticsSuccess, getVehicles, vehicles }) => {
  const [selPeriod, setSelPeriod] = useState({
    startDate: options[6].value,
    endDate: moment().toString(),
  });

  const [filtered, setFiltered] = useState(false);
  const [innerLoading, setInnerLoading] = useState(false);
  const [moreInfo, setMoreInfo] = useState(false);
  const innerContainer = useRef();

  useEffect(() => {
    setInnerLoading(true);
    getStatisticsSuccess({ type: "vehicles", loading: true });
    getVehicles({
      startDate: options[6].value,
      endDate: moment().startOf("day").format("YYYY-MM-DD") + "T00:00:00.000Z",
      returnCached: true,
      onSuccess: () => setInnerLoading(false),
    });
  }, []);

  useEffect(() => {
    if (filtered) {
      setInnerLoading(true);
      getVehicles({
        ...selPeriod,
        onSuccess: () => setInnerLoading(false),
      });
    }
  }, [selPeriod]);

  useEffect(() => {
    if (!moreInfo) innerContainer.current.scrollTo({ top: 0, behavior: "smooth" });
  }, [moreInfo]);

  return (
    <div className="statistics-sessions-container col">
      {innerLoading ? (
        <Statistics.LoaderInline />
      ) : (
        <>
          <div style={{ display: "flex", width: "100%" }}>
            <h2 className="main-header">
              <b>vehicles</b>
            </h2>
            <Statistics.SelectPeriod selPeriod={selPeriod} setSelPeriod={setSelPeriod} setFiltered={setFiltered} startField={"startDate"} endField={"endDate"} />
          </div>
          <div className="border-container background" style={{ marginRight: "1%" }}>
            <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
              <div className="indicator-circle dark-gray" />
              <h2 className="statistics-info-box-text" style={{ width: "95%", fontSize: "17px" }}>
                <b style={{ fontSize: "19px" }}>{vehicles?.total || "0"}</b> ADDED IN THE APP
              </h2>
            </div>
          </div>
          <div className="statistics-sessions-content" ref={innerContainer} style={{ overflowY: "scroll" }}>
            {horizontalObjects.map((obj, i) => {
              return (
                <div className="statistics-sessions-section col" style={{ marginBottom: "20px" }} key={i}>
                  <div
                    style={{
                      margin: "5px 0px",
                    }}
                    key={i}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <h4 style={{ fontSize: "15px", margin: "5px 0px" }}>{obj.head}</h4>
                    </div>
                    {vehicles.loading ? (
                      <Statistics.LoaderInline />
                    ) : (
                      <>
                        <Statistics.Horizontal
                          data={
                            vehicles?.[obj.valueKey]
                              ? obj.valueKey === "byBrand" && !moreInfo
                                ? Object.keys(vehicles?.[obj.valueKey])
                                    ?.slice(0, 10)
                                    ?.map((key) => {
                                      return {
                                        value: key,
                                        label: vehicles?.[obj.valueKey]?.[key],
                                      };
                                    })
                                : Object.keys(vehicles?.[obj.valueKey])?.map((key) => {
                                    return {
                                      value: key === "{{OTHERS}}" ? "ДРУГИ" : key,
                                      label: vehicles?.[obj.valueKey]?.[key],
                                    };
                                  })
                              : []
                          }
                        />
                        {obj.valueKey === "byBrand" && vehicles?.[obj.valueKey] && Object.keys(vehicles?.[obj.valueKey])?.length > 10 ? <Inputs.Button text={moreInfo ? "LESS" : "MORE"} selected onClick={() => setMoreInfo((prevState) => !prevState)} style={{ marginTop: "20px", backgroundColor: "#002C78" }} /> : null}
                      </>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  vehicles: state.dashboard.vehicles,
});
const mapDispatchToProps = (dispatch) => ({
  getVehicles: (payload) => dispatch(getVehicles(payload)),
  getStatisticsSuccess: (payload) => dispatch(getStatisticsSuccess(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
