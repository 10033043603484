/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { forwardRef } from "react";
import { uploadFile } from "../../../utilites/files";

const ImageInput = ({
  label,
  compulsory,
  style,
  inputStyle,
  className,
  onChange,
  setValue,
  innerWidth,
  files,
  disabled,
  ...props
}) => {
  const uploadRef = useRef();
  const handleUpload = async ({ target: { files } }) => {
    uploadFile(files[0]).then((data) => {
      if (onChange) {
        onChange(data.location);
      }
    });
  };

  return (
    <div className="input-container" style={style}>
      {label && (
        <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
          {label}{" "}
          {compulsory && (
            <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
          )}
        </label>
      )}

      <div
        className={`prefix-input-container ${
          !props.value && "dotted"
        } ${className}`}
        style={{
          width: innerWidth ? "98%" : "50%",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => {
          if (uploadRef) {
            if (!props.value) uploadRef.current.click();
          }
        }}
      >
        {!props.value ? (
          <>
            {" "}
            <h3 style={{ marginLeft: "4%" }}>UPLOAD</h3>
            <div className="upload-icon" />
            <input
              ref={uploadRef}
              style={{ display: "none" }}
              disabled={disabled}
              type="file"
              accept={!files && "image/*"}
              onChange={handleUpload}
            />
          </>
        ) : (
          <>
            <h3 style={{ marginLeft: "4%" }}>
              {props.value.split("/")[props.value.split("/")?.length - 1].slice(0, 17)}
            </h3>
            <div
              className="close-icon"
              style={{ marginRight: "4%" }}
              onClick={() => {
                if (innerWidth) setValue("");
                else setValue("logo", "");
              }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImageInput;
