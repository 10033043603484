import { nanoid } from "nanoid";
import { API_URL } from "../config/settings";
import _ from "lodash";
import * as XLSX from "xlsx";
import { Headers } from "./Headers";

export const uploadFile = async (file) => {
  let filename = file.name;
  filename = filename.split("%").join("").split(" ").join("");
  filename = `amarant-articles/${nanoid()}_${filename}`;
  const response = await fetch(`${API_URL}/cloudServices/s3Upload`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? "" }) });
  if (!response.ok) throw new Error("Грешка");
  const {
    payload: { presignedUrl, url },
  } = await response.json();
  const response2 = await fetch(presignedUrl, { method: "PUT", body: file, headers: { "Content-Type": file?.type ?? "" } });
  if (!response2.ok) throw new Error("Грешка");
  return { location: url };
};

export const uploadFiles = async (files) => await Promise.all([...files].map((file) => uploadFile(file)));

export const downloadFile = async (url) => {
  const response = await fetch(`${API_URL}/cloudServices/s3DownloadBA`, { method: "POST", headers: Headers.get_basicAuth(), body: JSON.stringify({ url }) });
  if (!response.ok) throw new Error("Грешка");
  const { payload } = await response.json();
  return payload?.presignedUrl;
};

export const parseXLSX = (file, handleResults) => {
  const reader = new FileReader();
  reader.onload = function (e) {
    const data = e.target.result;
    const wb = XLSX.read(data, { type: "binary" });
    const results = wb.SheetNames.map((sheet) => XLSX.utils.sheet_to_json(wb.Sheets[sheet], { header: 1, raw: false }));
    const parsed = _.flatten(
      results
        .filter((arr) => !_.isEmpty(arr))
        .map((sheet) => {
          const first = sheet.shift().map((header) => header?.trim() || "");
          return sheet.map((el) => el.reduce((acc, cur, i) => ({ ...acc, [first[i]]: cur.toString().trim() }), {}));
        })
    );
    handleResults(parsed);
  };
  reader.readAsBinaryString(file);
};

const Files = {
  async uploadFile(file, name) {
    let filename = file.name;
    filename = filename.split("%").join("").split(" ").join("");
    filename = `amarant-tasks/${nanoid()}_${filename}`;
    const response = await fetch(`${API_URL}/cloudServices/s3Upload`, { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ objectKey: filename, mimeType: file?.type ?? "" }) });
    if (!response.ok) throw new Error("Грешка");
    const {
      payload: { presignedUrl, url },
    } = await response.json();
    const response2 = await fetch(presignedUrl, { method: "PUT", body: file, headers: { "Content-Type": file?.type ?? "" } });
    if (!response2.ok) throw new Error("Грешка");
    return { location: url };
  },
};

export default Files;
