/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { history } from "../../../config/stores";
import moment from "moment";
import { emit } from "../../../utilites/helpers";
import { setLastOpenedTask, startLoading, stopLoading } from "../../../actions";
import { downloadFile, uploadFiles } from "../../../utilites/files";
import Inputs from "../../../components/Inputs";
import ModalComponent from "../../../components/ModalComponent/ModalComponent";
import Modals from "../../../components/ModalComponent/Modals";
import { User } from "../../../utilites";
import { toast } from "react-toastify";

export default function ReviewTask() {
  const { _id } = useParams();
  const dispatch = useDispatch();

  const taskData = useSelector((state) => state.tasks.task);
  const users = useSelector((state) => state.tasks.users);
  const filters = useSelector((state) => state.tasks.filters);
  const socket = useSelector(({ tasks }) => tasks?.taskSocket) || null;

  const [chosen, setChosen] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [editTasks, setEditTasks] = useState([]);
  const [initialTask, setInitialTask] = useState(true);
  const [comments, setComments] = useState([]);
  const [activities, setActivities] = useState([]);
  const [taskEdit, setTaskEdit] = useState(false);
  const [files, setFiles] = useState([]);
  const [chosenTab, setChosenTab] = useState("description");
  const [chosenSecondTab, setChosenSecondTab] = useState("comments");
  const [commentQuery, setCommentQuery] = useState("");
  const [isHovered, setIsHovered] = useState(false);
  const [deleteModal, setDeleteModal] = useState({ open: false, id: null });
  const [data, setData] = useState({
    _id: "",
    author: "",
    assignedTo: [],
    description: "",
    status: "",
    completedAt: null,
    completedBy: null,
    deletedAt: null,
    deletedBy: null,
    title: "",
    category: {},
    priority: "",
    startDate: null,
    endDate: null,
    orderIndex: 0,
    author: "",
    createdAt: null,
    updatedAt: null,
    __v: 0,
    _comments: [],
    _steps: [],
    _files: [],
    _activity: [],
  });

  let isFinished = data?.status === "completed";

  const activitiesMap = {
    "create-task": "създаде задача",
    "update-task": "редактира задача",
    "reopen-task": "отбеляза задача като отворена",
    "complete-task": "завърши задача",
    "upload-file": "качи файл",
    "delete-file": "изтри файл",
    "create-step": "създаде задача в списъка",
    "step-completed": "завърши задача от списъка",
    "step-not-completed": "отбеляза задача от списъка като незавършена",
  };

  const statusMap = {
    upcoming: "Предстояща",
    active: "Активна",
    overdue: "Просрочена",
    completed: "Завършена",
  };

  const priorityTypes = {
    low: { name: "Нисък приоритет", color: "green" },
    medium: { name: "Среден приоритет", color: "yellow" },
    high: { name: "Висок приоритет", color: "red" },
  };

  const renderActivityType = (activityType) => {
    return activityType === "new-comment" ? "добави коментар" : activitiesMap[activityType] || "";
  };

  const renderActivityInfo = (activity) => {
    const activityType = activity?.action;

    switch (activityType) {
      case "upload-file":
        return (
          <div className="bold link" onClick={() => window.open(activity?.metadata?.fileUrl)}>
            {activity?.metadata?.fileName}
          </div>
        );
      case "delete-file":
        return <div className="bold link default">{activity?.metadata?.fileName}</div>;
      case "create-step":
        return <div className="bold">{activity?.metadata?.stepText}</div>;
      case "step-completed":
        return <div className="bold">{activity?.metadata?.stepText}</div>;
      case "step-not-completed":
        return <div className="bold">{activity?.metadata?.stepText}</div>;
      default:
        return;
    }
  };

  const handleDeleteStep = (el, index) => {
    if (index > -1) {
      let newEditTasks = editTasks.slice();
      newEditTasks.splice(index, 1);
      setEditTasks(newEditTasks);

      if (!newEditTasks.length) setInitialTask(true);
    }
  };

  const handleDeleteComment = (el) => {
    emit(socket, { action: "task/deleteComment", payload: { commentId: el } });
    emit(socket, { action: "task/getTask", payload: { taskId: _id } });
    toast.success("Comment deleted successfully!");
  };

  const handleCreateStep = () => {
    let newEditTasks = editTasks.slice();
    newEditTasks.push({ text: "" });
    setEditTasks(newEditTasks);

    if (initialTask) {
      setInitialTask(false);
    }
  };

  const handleSaveSteps = () => {
    console.log(editTasks);
    emit(socket, { action: "task/updateSteps", payload: { taskId: _id, steps: editTasks?.filter((task) => task?.text?.length > 0) } });
    emit(socket, { action: "task/getTask", payload: { taskId: _id } });
    setTaskEdit(false);
    // setTasks(editTasks);
    // emit(socket, { action: 'task/getTask', payload: { taskId: _id } })
  };

  const createComment = () => {
    emit(socket, { action: "task/createComment", payload: { taskId: _id, text: commentQuery } });
    emit(socket, { action: "task/getTask", payload: { taskId: _id } });
    setCommentQuery("");
    // onSend(commentQuery, comments[0]?.machineSceduleWork)
  };

  const renderAuthor = (author, users) => {
    if (author && users) {
      return author?.name + " " + author?.lastName;
    } else {
      return "";
    }
  };

  const finishTask = () => {
    emit(socket, { action: "task/toggleTaskCompleted", payload: { taskId: _id } });
    emit(socket, { action: "task/getTasks", payload: {} });
  };

  useEffect(() => {
    // dispatch(getPerformers())
    emit(socket, { action: "task/getUsers" });

    if (Object.values(filters || {}).length) {
      emit(socket, { action: "task/getTasks", payload: { filters: filters } });
    } else {
      emit(socket, { action: "task/getTasks", payload: {} });
    }

    return emit(socket, { action: "task/leaveActiveRoom", payload: { taskId: _id } });
  }, []);

  useEffect(() => {
    if (socket)
      socket.onmessage = (event) => {
        const receivedData = JSON.parse(event?.task || event?.payload?.task);
        setData(receivedData);
        dispatch(setLastOpenedTask(receivedData));
      };
    emit(socket, { action: "task/getTask", payload: { taskId: _id } });
  }, [socket]);

  useEffect(() => {
    if (taskData && typeof taskData === "object" && Object.keys(taskData)?.length) {
      setData(taskData);
      setTasks(taskData?._steps);
      setEditTasks(taskData?._steps);
      setComments(taskData?._comments);
      setActivities(taskData?._activity);
      setFiles(taskData?._files);
      setChosen(() => taskData?._steps?.filter((el) => el?.isCompleted) || []);

      if (!taskData?._steps?.length) {
        setInitialTask(true);
      } else {
        setInitialTask(false);
      }
    }
  }, [taskData]);

  return (
    <div className="review-task-wrapper">
      <div className="review-task-inner-wrapper left">
        {isHovered && (
          <div className={`priority-popup-wrapper ${priorityTypes[data?.priority]?.color}`}>
            <h3>{priorityTypes[data?.priority]?.name || ""}</h3>
          </div>
        )}

        <div className="top-wrapper" style={{ height: "9%" }}>
          {!isFinished && <div className={`border ${priorityTypes[data?.priority]?.color}`} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} />}
          <div className="headings-wrapper">
            <img
              src={require("../../../assets/icons/arrow-back-blue.svg")}
              alt="back"
              className="icon"
              onClick={() => {
                history.push("/tasks");
                // window.close();
              }}
            />
            <h2>{data?.title}</h2>
            <h3>
              Статус: <span className={isFinished ? "grey" : "blue"}>{statusMap[data?.status] || ""}</span>
            </h3>
          </div>

          <div className="buttons-wrapper">
            {isFinished ? (
              <>
                <img src={require("../../../assets/icons/trash.svg")} className="icon" onClick={() => setDeleteModal({ open: true, id: _id })} />
                <button className="secondary-button" onClick={() => history.push(`/task/${_id}`)}>
                  Отвори
                </button>
              </>
            ) : (
              <>
                {/* <img src={require("../../../assets/icons/edit-icon.svg")} alt="edit" style={{ width: "45px", height: "45px" }} onClick={() => history.push(`/task/${_id}`)} /> */}
                <Inputs.Button text="Завърши задача" onClick={() => finishTask()} selected />
              </>
            )}
          </div>
        </div>

        <div className="main-wrapper" style={{ height: "31%" }}>
          <div className="main-top-wrapper border">
            <h2>За задачата</h2>

            <div className="flex">
              <p>Изпълнител: </p>

              <div className="flex wide">
                <span className="flex">
                  {data?.assignedTo?.map((el, i) => {
                    return <p className="performer">{el?.name + " " + el?.lastName + ` `}</p>;
                  })}
                </span>
              </div>
            </div>
          </div>

          <div className="main-middle-wrapper">
            <div className="info-wrapper">
              <h3>Начало:</h3>
              <p>{moment(data?.startDate).format("DD.MM.YYYY")}</p>
            </div>

            {isFinished ? (
              <div className="info-wrapper no-circle">
                <h3>Завършена на:</h3>
                <p>{moment(data?.completedAt).format("DD.MM.YYYY")}</p>
              </div>
            ) : (
              <>
                <div className="info-wrapper">
                  <h3>Край:</h3>
                  <p>{data?.endDate ? moment(data?.endDate).format("DD.MM.YYYY") : "Няма"}</p>
                </div>

                <div className="info-wrapper">
                  <h3>Добавена на:</h3>
                  <p>{moment(data?.startDate).format("DD.MM.YYYY")}</p>
                </div>

                <div className="info-wrapper no-circle">
                  <h3>Възложена от:</h3>
                  <p>{renderAuthor(data?.author, users)}</p>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="bottom-wrapper" style={{ height: "60%" }}>
          <div className="options-wrapper">
            <div className={`option-label ${chosenTab === "description" ? "selected" : ""}`} onClick={() => setChosenTab("description")}>
              Описание
            </div>
            <div className={`option-label ${chosenTab === "list" ? "selected" : ""}`} onClick={() => setChosenTab("list")}>
              Списък със задачи
            </div>
          </div>

          {chosenTab === "description" && (
            <div className="scroll-tab" style={{ height: "95%" }}>
              {/* <p>{data?.description}</p> */}
              <div className="text" dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
          )}

          {/* {(chosenTab === 'list' && !initialTask) && */}
          {chosenTab === "list" && (
            <div className="list-wrapper">
              {tasks?.length && !taskEdit && !initialTask ? (
                <div className="top-wrapper flex">
                  <p className={`${tasks?.filter((el) => el?.isCompleted)?.length ? "blue" : "white"}`}>{`Прогрес (${tasks?.filter((el) => el?.isCompleted)?.length}/${tasks?.length})`}</p>
                  <img className="edit-icon" src={require("../../../assets/icons/edit-icon.svg")} alt="edit" onClick={() => setTaskEdit(true)} />
                </div>
              ) : (
                <></>
              )}

              {tasks?.length && !taskEdit ? (
                <div className={`scroll-tab ${initialTask ? "small" : ""}`}>
                  {tasks?.map((task, index) => {
                    const checked = chosen.findIndex((el) => el === task?.text) !== -1;
                    //  const checked = chosen.findIndex(el => el === task?.text) !== -1;
                    return (
                      <div className="check-list" key={`checklist-task-${index}`}>
                        <div
                          className={`checkbox-container ${checked && "selected"}`}
                          onClick={() => {
                            const newChosen = chosen.slice(0);
                            const indexChosen = newChosen.findIndex((el) => el === task?.text);
                            if (indexChosen === -1) {
                              newChosen.push(task?.text);
                            } else {
                              newChosen.splice(indexChosen, 1);
                            }
                            setChosen(newChosen);
                            emit(socket, { action: "task/toggleStepCompleted", payload: { stepId: task?._id } });
                            emit(socket, { action: "task/getTask", payload: { taskId: _id } });
                          }}
                        >
                          {(checked || task?.isCompleted) && <div className="checkbox" />}
                        </div>
                        <div className={`task ${(checked || task?.isCompleted) && "selected"}`}>{task?.text}</div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}

              <div className="scroll-tab small">
                {initialTask && (
                  <div className="initial-state-wrapper task">
                    <Inputs.TextInput
                      className="text"
                      value={editTasks[0]?.text || ""}
                      placeholder="Елемент 1"
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleCreateStep();
                        }
                      }}
                      onChange={(e) => {
                        let newTasksToCreate = editTasks.slice();
                        newTasksToCreate.splice(0, 1, { text: e.target.value });
                        setEditTasks(newTasksToCreate);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {chosenTab === "list" && (taskEdit || !tasks.length) && (
            <div className="scroll-tab">
              <div className="tasks-wrapper">
                {!initialTask &&
                  editTasks?.map((el, index) => {
                    return (
                      <div className="task">
                        <Inputs.TextInput
                          className="text"
                          value={el?.text}
                          placeholder="Добави задача"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") handleCreateStep();
                          }}
                          onChange={(e) => {
                            let newTasksToUpdate = editTasks.slice();
                            newTasksToUpdate.splice(index, 1, { ...el, text: e.target.value });
                            setEditTasks(newTasksToUpdate);
                          }}
                        />
                        <img src={require("../../../assets/icons/trash.svg")} alt="remove" onClick={() => handleDeleteStep(el.text, index)} />
                      </div>
                    );
                  })}
              </div>

              <div className="flex">
                <button className="secondary-button" onClick={handleCreateStep}>
                  Добави нов
                </button>
                <button className="primary-button end" onClick={() => handleSaveSteps()}>
                  Запази
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="review-task-inner-wrapper right">
        <div className="dashboard-top-wrapper">
          <div className="headers-wrapper">
            <h3 className={chosenSecondTab === "comments" ? "selected" : "comment"} onClick={() => setChosenSecondTab("comments")}>
              Коментари
            </h3>
            <h3 className={chosenSecondTab === "activity" ? "selected" : "activity"} onClick={() => setChosenSecondTab("activity")}>
              Активност
            </h3>
            <h3 className={chosenSecondTab === "files" ? "selected" : "activity"} onClick={() => setChosenSecondTab("files")}>
              Файлове
            </h3>
          </div>

          {chosenSecondTab === "files" && (
            <div className="upload-button-wrapper">
              <Inputs.FileUpload
                style={{ width: "30%", margin: "0px 10px" }}
                icon
                files
                onChange={(e) => {
                  const nameArray = Object.keys(e.target.files).map((key) => {
                    return e.target.files[key].name;
                  });
                  dispatch(startLoading());
                  uploadFiles(e.target.files).then((result) => {
                    let data = result.map((el, index) => {
                      return { name: nameArray[index], url: el.location };
                    });
                    if (data) {
                      data.map((file) => {
                        return emit(socket, { action: "task/uploadFile", payload: { taskId: _id, name: file?.name, url: file?.url } });
                      });
                      emit(socket, { action: "task/getTask", payload: { taskId: _id } });
                      dispatch(stopLoading());
                    }
                  });
                }}
              />
            </div>
          )}
        </div>

        <div className="dashboard-main-wrapper">
          {chosenSecondTab === "comments" && (
            <div className="tab-wrapper comments">
              <div className="comments-layout top">
                {comments?.map((comment, index) => {
                  return (
                    <div className="comment-wrapper">
                      <div className="text-wrapper">
                        <div className="comment-title">{comment?.user?.name + " " + comment?.user?.lastName}</div>
                        <div className="date">{moment(comment?.createdAt).format("DD.MM.YYYY, HH:mm")}</div>
                      </div>
                      <div className="flex-container items-center full-width space-between align-center">
                        <div className="comment-wrapper" style={{ marginBottom: "0px", width: comment?.user?._id === User.getUser()?._id && "95%" }}>
                          {comment?.text}
                        </div>
                        {comment?.user?._id === User.getUser()?._id && <div className="delete-icon" style={{ width: "20px", height: "20px" }} onClick={() => handleDeleteComment(comment?._id)} />}
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="comments-layout bottom">
                <div className="message-box-wrapper">
                  <Inputs.TextAreaInput
                    placeholder="Коментар"
                    inputStyle={{ height: "55px" }}
                    onKeyDown={(e) => {
                      console.log(e);
                      if (e.key === "Enter" && !e?.shiftKey) {
                        e.preventDefault();
                        createComment();
                      }
                    }}
                    value={commentQuery}
                    onChange={(e) => setCommentQuery(e.target.value)}
                  />
                </div>
              </div>
            </div>
          )}

          {chosenSecondTab === "activity" && (
            <div className="tab-wrapper activity">
              {activities?.map((activity, index) => {
                return (
                  <div className="single-activity-wrapper">
                    <div className="icon-date-wrapper">
                      <img src={require("../../../assets/icons/blueCircle.svg")} />
                      <div className="activity-date">{moment(activity?.createdAt).format("HH:mm | DD.MM.YYYY")}</div>
                      {/* <div className='activity-date'>12:45 | 12.12.2020</div> */}
                    </div>

                    <div className="activity-description">
                      <span className="bold">{`${activity?.user?.name + " " + activity?.user?.lastName} `}</span>
                      <span className="light">{renderActivityType(activity?.action)}</span>
                      {(activity?.action === "upload-file" || activity?.action === "delete-file" || activity?.action === "create-step" || activity?.action === "step-completed" || activity?.action === "step-not-completed") && <span className="light">:</span>}
                      {renderActivityInfo(activity)}
                    </div>
                  </div>
                );
              })}

              <div className="line-wrapper" />
            </div>
          )}

          {chosenSecondTab === "files" && (
            <div className="tab-wrapper files">
              {files?.map((file, index) => {
                return (
                  <div className={`row-wrapper ${index !== files.length - 1 ? "border" : ""}`}>
                    <div
                      className="flex col"
                      style={{ cursor: "pointer" }}
                      onClick={async () => {
                        const lockedBuckets = ["amarant2", "amarant-rpa", "amarant-docs", "amarant-files"];
                        if (lockedBuckets.find((el) => file?.url?.includes(el))) {
                          const downloaded = await downloadFile(file?.url);
                          window.open(downloaded);
                        } else window.open(file?.url);
                      }}
                    >
                      <div className="bold">{file?.name?.slice(0, 40) + "..."}</div>
                      <div className="light">{`${moment(file?.createdAt).format("HH:mm | DD.MM.YYYY ")} | ${file?.user?.name + " " + file?.user?.lastName}`}</div>
                    </div>
                    <div className="flex">
                      <img
                        src={require("../../../assets/icons/trash.svg")}
                        alt="remove"
                        onClick={() => {
                          let newFiles = JSON.parse(JSON.stringify(files));
                          newFiles = newFiles.filter((el) => el.name !== file.name);
                          setFiles(newFiles);
                          emit(socket, { action: "task/deleteFile", payload: { fileId: file?._id } });
                          emit(socket, { action: "task/getTask", payload: { taskId: _id } });
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <ModalComponent
        open={deleteModal.open}
        children={
          <Modals.DeleteModal
            header="Изтриване на задача"
            body={"Сигурни ли сте, че искате да изтриете този елемент?"}
            handleClose={() =>
              setDeleteModal({
                open: false,
                id: "",
              })
            }
            handleSubmit={() => {
              console.log("tuk");
              emit(socket, { action: "task/deleteTask", payload: { taskId: deleteModal.id } });
              window.close();
            }}
          />
        }
      />
    </div>
  );
}
