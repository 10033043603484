/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import {
  addArticle,
  editArticle,
  deleteArticle,
  getCurrentArticle,
} from "../../actions";
import Inputs from "../../components/Inputs";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import "./styles.scss";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { uploadFiles } from "../../utilites/files";

const ArticleForm = ({
  addArticle,
  article,
  getCurrentArticle,
  deleteArticle,
  editArticle,
}) => {
  const article_id = window.location.href.split("/")[5];
  const [editEnable, setEditEnable] = useState(false);
  const uploadRef = useRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (article_id) {
      getCurrentArticle({
        id: article_id,
        onSuccess: (res) => {
          setValue("title", res.title);
          setValue("coverPhoto", res.coverPhoto);
          setValue("text", res.text);
          setFiles(
            res.photos.map((photo) => {
              return {
                location: photo,
              };
            })
          );
        },
      });
    }
  }, []);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    title: "",
    coverPhoto: "",
    photos: [],
    text: "",
  });

  return (
    <div className="main-container">
      <div className="inner-header-container">
        <div className="left-part" style={{ width: "92%" }}>
          <div
            className="close-icon"
            onClick={() => history.push("/website")}
          />
          <h2 className="inner-title">
            {article_id
              ? editEnable === true
                ? "EDIT ARTICLE"
                : "REVIEW ARTICLE"
              : "ADD NEW ARTICLE"}
          </h2>
        </div>
        {article_id ? (
          editEnable === true ? (
            <>
              <Inputs.Button
                text="DELETE"
                deleted
                style={{ width: "7%", marginRight: "2%" }}
                onClick={() =>
                  deleteArticle({
                    id: article_id,
                    onSuccess: (response) => {
                      toast.success("Article deleted successfully");
                      history.push("/website");
                    },
                  })
                }
              />
              <Inputs.Button
                text="SAVE"
                selected
                style={{ width: "7%", marginRight: "2%" }}
                onClick={handleSubmit((e) => {
                  editArticle({
                    ...e,
                    photos: files.map((file) => file.location),
                    _id: article_id,
                    onSuccess: (res) => {
                      toast.success("Article updated successfully");
                      history.push("/website");
                    },
                  });
                })}
              />
            </>
          ) : (
            <Inputs.Button
              text="EDIT"
              selected
              style={{ width: "7%" }}
              onClick={() => setEditEnable(true)}
            />
          )
        ) : (
          <Inputs.Button
            text="ADD"
            selected
            style={{ width: "7%" }}
            onClick={handleSubmit((e) => {
              addArticle({
                ...e,
                photos: files.map((file) => file.location),
                onSuccess: (res) => {
                  toast.success("Article added successfully!");
                  history.push("/website");
                },
              });
            })}
          />
        )}
      </div>
      <div className="body-container">
        <Inputs.TextInput
          label={"Title"}
          compulsory
          disabled={article_id ? (!editEnable ? true : false) : false}
          className={errors.title && "failed"}
          {...register(`title`, { required: true })}
        />

        <div className="input-container" style={{ margin: "10px 0px" }}>
          <label style={{ display: "flex", width: "100%", fontSize: "0.8rem" }}>
            Article Images
            <span style={{ paddingLeft: "5px", color: "black" }}>*</span>
          </label>

          <div className="flex-container full-width">
            <div
              className={`prefix-input-container`}
              style={{
                width: "19.5%",
                justifyContent: "space-between",
                cursor: "pointer",
                border: "1px dotted rgb(165, 164, 164)",
              }}
              onClick={() => {
                uploadRef.current.click();
              }}
            >
              {" "}
              <h3 style={{ marginLeft: "4%" }}>UPLOAD</h3>
              <div className="upload-icon" />
              <input
                ref={uploadRef}
                style={{ display: "none" }}
                type="file"
                accept={"image/*"}
                disabled={article_id ? (!editEnable ? true : false) : false}
                multiple
                onChange={(e) => {
                  uploadFiles(e.target.files).then((data) => {
                    setFiles(data);
                  });
                }}
              />
            </div>
            {files.length > 0 &&
              files.map((file, i) => {
                return (
                  <div
                    key={i}
                    className={`prefix-input-container`}
                    style={{
                      width: "15%",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      border: "1px dotted rgb(165, 164, 164)",
                      marginLeft: "1.5%",
                    }}
                  >
                    <h3 style={{ marginLeft: "4%" }}>
                      {file?.location.split("/")[4].slice(0, 12)}
                    </h3>
                    <div
                      className="close-icon"
                      style={{ marginRight: "4%" }}
                      onClick={() => {
                        const newFiles = files.slice(0);
                        newFiles.splice(i, 1);
                        setFiles(newFiles);
                      }}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <Controller
          control={control}
          name={"coverPhoto"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.ImageInput
              label={"Cover Photo"}
              compulsory
              style={{ width: "40%", margin: "10px 0px" }}
              value={value}
              disabled={article_id ? (!editEnable ? true : false) : false}
              onChange={onChange}
              setValue={setValue}
              className={error && "failed"}
            />
          )}
          rules={{ required: true }}
        />
        <Controller
          control={control}
          name={"text"}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Inputs.RichText
              label={"Article"}
              compulsory
              allTools
              style={{ width: "100%", height: "50%" }}
              value={article_id && value}
              edit={article_id && true}
              onChange={(e) => { setValue("text", e); }}
              className={error && "failed"}
            />
          )}
          rules={{ required: true }}
        />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  article: state.articles.article,
});
const mapDispatchToProps = (dispatch) => ({
  addArticle: (payload) => dispatch(addArticle(payload)),
  deleteArticle: (payload) => dispatch(deleteArticle(payload)),
  editArticle: (payload) => dispatch(editArticle(payload)),
  getCurrentArticle: (payload) => dispatch(getCurrentArticle(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ArticleForm);
